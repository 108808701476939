import { callPersonalRequest, fetchBalance, fetchUser } from 'application/data/api/user'
import { useQuery } from '@tanstack/react-query'
import { CallPersonalRequestData } from 'application/domain/types/RequestTypes'

import { AuthUser } from './../../entity/user/User'

export const useUser = (token: string | null) => {
  const query = useQuery(['profile', token || ''], () => fetchUser(token), {
    enabled: token ? true : false,
    refetchInterval: token ? 10000 : false,
    // retry: false,
  })

  return { ...query, data: query.data && query.data.data ? query.data.data : null }
}

export const useBalance = (user: AuthUser) => {
  const query = useQuery(['balance'], () => fetchBalance(user), {
    enabled: !!user,
  })

  return query
}

export const callPersonal = async (sendData: CallPersonalRequestData) =>
  callPersonalRequest(sendData)
