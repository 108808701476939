/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from '@reduxjs/toolkit'
import { Product } from 'application/domain/entity/product/Product'
import { addProduct, editProduct, setBasket } from 'application/domain/store/basketReducer'
import getRandomInRange from 'application/utils/getRandomInRange'
import _ from 'lodash'
import moment from 'moment'

type AddProdOptions = {
  selected_temperature: number
  selected_volume: number
  sort_by_min_price?: boolean
  is_edit?: boolean
  callback?: Function
}
export const addProductToBasket =
  (product: Product, options: AddProdOptions) => (dispatch: Dispatch) => {
    const { selected_temperature, selected_volume, callback } = options
    const prod = _.cloneDeep(product)
    if (prod.isDrink && prod.can6 && selected_temperature) {
      prod.temperature = selected_temperature === 1 ? 'warm' : 'hot'
    }
    if (!options.is_edit) {
      prod.uniq_id = `${moment().unix()}${getRandomInRange(1, 1000)}`
    }
    if (prod.options) {
      // prod.options = prod.selected_options || []
      // prod.options.unshift(dat.options[0])
      if (selected_volume) {
        prod.varieties = prod.varieties.map((item: any) => {
          item.selected = false
          if (item.variety_id === selected_volume) {
            item.selected = true
          }
          return item
        })
      } else {
        prod.varieties[0].selected = true
      }
      if (options.is_edit) {
        editProduct(prod)(dispatch)
      } else {
        addProduct(prod)(dispatch)
      }
      if (callback) {
        callback()
      }
    }
  }

export const addProductWithMinPrice =
  (prod: Product, options: { callback?: Function }) => (dispatch: Dispatch) => {
    const { callback } = options
    let prodClone = _.cloneDeep(prod)
    prodClone.uniq_id = `${moment().unix()}${getRandomInRange(1, 1000)}`
    if (prodClone.isDrink && prodClone.can6) {
      prodClone.temperature = 'warm'
    }
    if (prodClone.varieties && prodClone.varieties.length) {
      const min_val: any = _.sortBy(prodClone.varieties, 'cost')
      if (min_val && min_val[0]) {
        prodClone.varieties = prodClone.varieties.map((item: any) => {
          item.selected = false
          if (min_val[0].variety_id === item.variety_id) {
            item.selected = true
          }
          return item
        })
      } else {
        prodClone.varieties[0].selected = true
      }
    }
    addProduct(prodClone)(dispatch)
    if (callback) {
      callback()
    }
  }

export const multipleAddToBasket = (products: Array<Product>) => (dispatch: Dispatch) => {
  const newBasket = products.map((item) => {
    item.uniq_id = `${moment().unix()}${getRandomInRange(1, 1000)}`
    item.varieties = [{ ...item.variety, selected: true, variety_id: item.variety.id }]
    item.temperature = 'warm'
    const options = item.options.map((o) => ({
      ...o,
      id: o.id,
      name: o.name,
      variety_id: o.id,
      selected: true,
      items: [{ ...o, selected: true, variety_id: o.id, image: '', description: '' }],
    }))

    //@ts-ignore
    item.options = options
    return item
  })
  return setBasket(newBasket)(dispatch)
}
