import React from 'react'
import { PageLayoutProvider } from 'application/presentation/common/PageLayout/PageLayoutContext'
import { Route, Routes } from 'react-router-dom'
import s from 'AppView.module.scss'

import MainPage from '../pages/MainPage'
import ProductPage from '../pages/ProductPage'
import BasketPage from '../pages/BasketPage'
import CreateOrderPage from '../pages/CreateOrderPage'
import OrderResultPage from '../pages/OrderResultPage'
import AuthorizationPage from '../pages/AuthorizationPage'
import OrdersListPage from '../pages/OrdersListPage'
import MapPage from '../pages/MapPage'
import ContactsPage from '../pages/ContactsPage'
import FreeCoffePage from '../pages/FreeCoffePage'
import EditProfilePage from '../pages/EditProfilePage'
import OutletsListPage from '../pages/OutletsListPage'
import OrderPage from '../pages/OrderPage/OrderPage'
import PasswordResetPage from '../pages/AuthorizationPage/PasswordResetPage'
import RegistrationPage from '../pages/AuthorizationPage/RegistrationPage'
import FranchisePage from '../pages/FranchisePage'
import OutletTipsPage from '../pages/OutletTipsPage'

const MenuNavigation = () => {
  return (
    <div className={s.page_content}>
      <PageLayoutProvider>
        <Routes>
          <Route
            //   index
            element={<MainPage />}
            path="/"
          />
          <Route element={<AuthorizationPage />} path="/authorization" />
          <Route element={<PasswordResetPage />} path="/password_reset" />
          <Route element={<RegistrationPage />} path="/registration" />

          <Route element={<FranchisePage />} path="/franchise" />
          <Route element={<OutletsListPage />} path="/outlets" />
          <Route element={<EditProfilePage />} path="/edit_profile" />
          <Route element={<ContactsPage />} path="/contacts" />
          <Route element={<FreeCoffePage />} path="/free_coffe" />
          <Route element={<MapPage />} path="/map" />
          <Route element={<OrdersListPage />} path="/orders" />
          <Route element={<OrderPage />} path="/orders/:id" />
          <Route element={<OrderPage />} path="/orders/:order_id" />
          <Route element={<ProductPage />} path="/product/:id" />
          <Route element={<BasketPage />} path="/basket" />
          <Route element={<CreateOrderPage />} path="/basket/order" />
          <Route element={<OrderResultPage />} path="/basket/order/result" />
          <Route element={<OutletTipsPage />} path="/tips" />
        </Routes>
      </PageLayoutProvider>
    </div>
  )
}

export default React.memo(MenuNavigation)
