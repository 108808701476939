import { fetchOutlets } from 'application/data/api/outlets'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { Outlet } from 'application/domain/entity/outlet/Outlet'

export const alohaIds = [40, 34]

export const getOutlets = async (outlet_id?: number) => {
  const data = await fetchOutlets()
  if (outlet_id) {
    const isAloha = alohaIds.indexOf(outlet_id) !== -1
    return {
      outlets: _.filter(data.outlets, (item: Outlet) =>
        isAloha ? alohaIds.indexOf(item.id) !== -1 : alohaIds.indexOf(item.id) === -1,
      ),
    }
  }
  return data
}

export const useOutlets = (outlet_id?: number) => {
  const query = useQuery(['outlets'], fetchOutlets, {
    refetchInterval: 30000,
  })

  if (outlet_id) {
    const isAloha = alohaIds.indexOf(outlet_id) !== -1
    const outlets = query.data
      ? _.filter(query.data.outlets, (item: Outlet) =>
          isAloha ? alohaIds.indexOf(item.id) !== -1 : alohaIds.indexOf(item.id) === -1,
        )
      : []
    return {
      ...query,
      data: outlets,
    }
  }

  return { ...query, data: query.data ? query.data.outlets : [] }
}
