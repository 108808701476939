import { fetchCategories } from 'application/data/api/categories'
import { Category } from 'application/domain/entity/category/Category'
import { useQuery } from '@tanstack/react-query'

export const useCategories = (outlet_id: number) => {
  const query = useQuery<{ categories: Array<Category> }>(
    ['categories', outlet_id || 0],
    () => fetchCategories(outlet_id),
    {
      refetchInterval: 20000,
    },
  )

  return { ...query, data: query.data ? query.data.categories : [] }
}
