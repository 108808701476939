import React, { useState, useCallback, useMemo } from 'react'
import { ReactComponent as BasketSvg } from 'images/icons/basket_icon.svg'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import cn from 'classnames'
import { RootState } from 'application/domain/store/reduxStore'
import { ReactComponent as BurgerSvg } from 'images/icons/burger_icon.svg'

import Loader from '../Loader'
import Menu from '../Menu'

import s from './Header.module.scss'

const MainPageHeader = () => {
  const { outlet, tableId } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
    tableId: userReducer.tableId,
  }))

  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  // const isAloha = useMemo(() => outlet && outlet.id === 40, [outlet])

  const basketCount = useSelector(({ basketReducer }: RootState) => basketReducer.basketCount)

  const onBurgerClick = useCallback(() => setMenuOpened(true), [])

  const table = useMemo(
    () =>
      tableId
        ? tableId === '-2'
          ? 'В зале'
          : tableId === '-1'
            ? 'На вынос'
            : tableId === '0'
              ? 'С собой'
              : `Стол №${tableId}`
        : 'Не указан номер стола',
    [tableId],
  )

  return (
    <>
      <div className={s.header}>
        <div className={s.side_item}>
          <div className={cn(s.side_item, s.burger)} onClick={onBurgerClick}>
            <BurgerSvg />
          </div>
        </div>
        <NavLink className={s.center} to="/outlets">
          {!outlet ? (
            <Loader color="#000" size={16} containerClassName={s.loader_container} />
          ) : (
            <>
              <div className={s.outlet_name}>{outlet.name}</div>
              <div className={s.table_name}>{table}</div>
            </>
          )}
        </NavLink>
        <NavLink className={s.side_item} to="/basket">
          <BasketSvg />
          {basketCount > 0 && <div className={s.counter}>{basketCount}</div>}
        </NavLink>
      </div>
      <Menu opened={menuOpened} setOpened={setMenuOpened} />
    </>
  )
}

export default React.memo(MainPageHeader)
