import React from 'react'

import Loader from '../Loader'
import { Button } from '../uiComponents'

import s from './PaymentCheckView.module.scss'

type Props = {
  onBackPress: Function
}

const PaymentCheckView = ({ onBackPress }: Props) => {
  return (
    <div className={`${s.container} ${s.wait_container}`}>
      <Loader containerClassName={s.loader} size={32} />
      <div className={s.subtitle}>Проверяем статус оплаты...</div>
      <div className={s.text}>Можете проверить списание в вашем банковском приложении</div>
      <div className={s.text}>
        Если вы отменили оплату, <br /> просто вернитесь назад
      </div>
      <Button
        text="Назад"
        onClick={onBackPress}
        color="orange"
        containerClassName={s.btn_container}
      />
    </div>
  )
}

export default React.memo(PaymentCheckView)
