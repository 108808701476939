import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/context/ToastNotify'
import { Button, CodeInput, MaskedTextInput } from 'application/presentation/common/uiComponents'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthUser } from 'application/domain/entity/user/User'
import { setUser, setUserToken } from 'application/domain/store/userReducer'

import s from '../CreateOrderPage.module.scss'

type Props = {
  setBottomBarHidden: Function
  user: AuthUser
}

const AuthBlock = ({ setBottomBarHidden, user }: Props) => {
  const timer = useRef<any>()
  const dispatch = useDispatch()
  const [seconds, setSeconds] = useState<number>(0)
  const [phone, setPhone] = useState<string>(user ? user.phone : '')
  const [clientIsLoading, setClientIsLoading] = useState<boolean>(false)
  const [showPin, setShowPin] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.phone) {
      setPhone(user.phone)
    }
  }, [user])

  useEffect(() => {
    if (seconds > 0) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)
    }
  }, [seconds])

  const sendSms = useCallback(() => {
    if (phone) {
      const formatted = phone.replace(/[^0-9]/g, '')
      if (formatted.length === 11) {
        setClientIsLoading(true)
        apiClient
          .post('/api/newClient', {
            phone: formatted,
          })
          .then(({ data }) => {
            setSeconds(60)
            if (data && data.status === 'OK') {
              setShowPin(true)
            } else {
              ToastNotify('Отправка кода возможна лишь раз в минуту')
            }
            setClientIsLoading(false)
          })
          .catch(() => {
            ToastNotify('Произошла ошибка при отправлении смс')

            setClientIsLoading(false)
          })
      } else {
        ToastNotify('Номер телефона не корректный')
      }
    } else {
      ToastNotify('Введите номер телефона')
    }
  }, [phone])

  const auth = useCallback(
    (data: any) => {
      setShowPin(false)
      localStorage.setItem('user_token', data.token.token)
      setUser(data.user)(dispatch)
      setUserToken(data.token.token)(dispatch)
      //@ts-ignore
      apiClient.defaults.headers.Authorization = `Bearer ${data.token.token}`
    },
    [dispatch],
  )

  return (
    <div>
      <MaskedTextInput
        title="Номер телефона"
        value={phone}
        onChange={setPhone}
        mask="+7 (999) 999-99-99"
        maskChar={'_'}
        inputMode="numeric"
        readOnly={user ? true : false}
        rightTxt={user ? user.name : ''}
        onFocus={() => setBottomBarHidden(true)}
        onBlur={() => setBottomBarHidden(false)}
      />
      {!user ? (
        <>
          {showPin ? (
            <>
              <div className={s.code_info}>
                Введите код, который вам скажут при ответе на звонок:
              </div>
              <CodeInput
                phone={phone}
                onSuccess={auth}
                onFocus={() => setBottomBarHidden(true)}
                onBlur={() => setBottomBarHidden(false)}
              />
            </>
          ) : (
            <></>
          )}
          <Button
            text={seconds > 0 ? `Отправить повторно (${seconds} сек.)` : 'Получить СМС'}
            onClick={sendSms}
            disabled={
              seconds > 0 || !phone || phone.replace(/[^0-9]/g, '').length !== 11 ? true : false
            }
            color="orange"
            isLoading={clientIsLoading}
            containerClassName={s.auth_btn}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(AuthBlock)
