import Header from 'application/presentation/common/Header/Header'
import { Button, CodeInput, ErrorMsg } from 'application/presentation/common/uiComponents'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import ToastNotify from 'application/presentation/context/ToastNotify'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import s from './AuthorizationPage.module.scss'
import { useAuthorization } from './useAuthorization'

const RegistrationPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [stage, setStage] = useState<number>(1)
  const [seconds, setSeconds] = useState<number>(60)
  const [code, setCode] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const phone = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('phone') || ''
  }, [location.search])

  const { errMsg, btnHidden, checkIsLoading, regIsLoading, register, sendCode, checkCode, timer } =
    useAuthorization({ setSeconds })

  const onPressHandler = useCallback(() => {
    if (stage === 1) {
      if (code.length < 4) {
        return sendCode(phone)
      }
      return checkCode(phone, code, () => setStage(2))
    }
    return register(phone, password, () => {
      ToastNotify('Регистрация прошла успешно')
      navigate('/')
    })
  }, [checkCode, code, navigate, password, phone, register, sendCode, stage])

  useEffect(() => {
    if (seconds > 0) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  return (
    <div className={s.container}>
      <Header title={'Регистрация'} />

      <div className={s.content}>
        {stage === 1 && (
          <>
            <div className={s.content_title}>
              Вам поступит звонок на номер {phone}. Введите последние 4 цифры номера
            </div>
            <CodeInput onChange={setCode} controlled phone={phone} onSuccess={() => {}} />
            {errMsg ? <ErrorMsg text={errMsg} containerStyle={s.errmsg} /> : <></>}
          </>
        )}
        {stage === 2 && (
          <>
            <div className={s.content_title}>Отлично!{'\n'}Теперь придумайте пин-код.</div>
            <CodeInput controlled onChange={setPassword} containerClassName={s.pass_pin} />

            {errMsg ? <ErrorMsg text={errMsg} containerStyle={s.errmsg} /> : <></>}
          </>
        )}
      </div>

      {!btnHidden && (
        <Button
          containerClassName={s.btn_container}
          color="orange"
          text={
            stage === 1
              ? code.length < 4
                ? `Отправить повторно ${seconds ? `(${seconds} сек.)` : ''}`
                : 'Далее'
              : 'Зарегистрироваться'
          }
          isLoading={checkIsLoading || regIsLoading}
          disabled={stage === 1 && code.length < 4 && seconds > 0}
          onClick={onPressHandler}
        />
      )}
    </div>
  )
}

export default React.memo(RegistrationPage)
