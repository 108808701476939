import { useOrder } from 'application/domain/useCase/orders/getOrders'
import Header from 'application/presentation/common/Header/Header'
import Loader from 'application/presentation/common/Loader'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import { ReactComponent as CoffeIcon } from 'images/icons/coffe_icon_gray.svg'

import s from './OrderPage.module.scss'
import OrderPageContent from './OrderPageContent'

const OrderPage = () => {
  const params = useParams()
  const location = useLocation()

  const { id, isOpenOrder } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search)
    const openOrder = urlParams.get('is_open_order')
    if (params && params.id) {
      return openOrder
        ? { id: parseInt(params.id, 10), isOpenOrder: true }
        : { id: parseInt(params.id, 10), isOpenOrder: false }
    }
    return {
      id: 0,
      isOpenOrder: false,
    }
  }, [params, location])

  const { data, isLoading } = useOrder(id, isOpenOrder)

  const { isCompleted, isNotActive } = useMemo(() => {
    if (data && !data.state) {
      return { isOpenOrder: true, isCompleted: false, isNotActive: false }
    }
    return { isOpenOrder: false, isCompleted: true, isNotActive: true }
  }, [data])

  const productsLength = useMemo(() => {
    if (data && data.products) {
      const prod = _.filter(data.products, { can6: 1 })
      return prod ? prod.length : 0
    }
    return 0
  }, [data])

  return (
    <div className={s.container}>
      <Header
        title={'Детали заказа'}
        rightContent={
          productsLength ? (
            <div className={s.coffe_count}>
              <CoffeIcon />
              {productsLength}
            </div>
          ) : (
            <></>
          )
        }
      />
      <div className={s.content}>
        {isLoading ? (
          <Loader />
        ) : data ? (
          <OrderPageContent data={data} isNotActive={isNotActive} isCompleted={isCompleted} />
        ) : (
          <div>пуста</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(OrderPage)
