import apiClient from 'application/data/apiClient'
import React, { useMemo } from 'react'
import { ReactComponent as PlusSvg } from 'images/icons/orange_plus.svg'
import ProductImage from 'application/presentation/common/uiComponents/ProductImage'

import s from './ActiveItem.module.scss'

type Props = {
  data: any
  className?: any
}

const ActiveItem = ({ data, className }: Props) => {
  const selectedVolume = useMemo(() => {
    if (data && data.variety) {
      return data.variety ? data.variety.name : ''
    }
    return ''
  }, [data])
  return (
    <div className={`${s.item_content} ${className || {}}`}>
      <ProductImage
        containerClassName={s.img_block}
        className={s.item_img}
        isDrink={data.isDrink}
        imageUrl={data.image}
      />
      <div className={s.item_info}>
        <div className={s.item_name}>{data.product_name}</div>
        <div className={s.item_volume}>{selectedVolume}</div>
        {data && data.options && data.options.length ? (
          <div className={s.additionals}>
            <div className={s.additionals_item}>
              <div className={s.additionals_plus}>
                <PlusSvg />
              </div>
              <div className={s.list}>
                {data.options.map((item: any, i: number) => (
                  <div className={s.additionals_txt} key={`additional_${i}_${item}`}>
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={s.price}>{data.full_cost} ₽</div>
    </div>
  )
}

export default React.memo(ActiveItem)
