import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import imagePng from 'images/selected_location.png'
import { ReactComponent as RingSvg } from 'images/icons/ring.svg'
import { ReactComponent as CoinsSvg } from 'images/icons/coins.svg'
import { ReactComponent as BludceSvg } from 'images/icons/bludce.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'
import ToastNotify from 'application/presentation/context/ToastNotify'
import { callPersonal } from 'application/domain/useCase/user/getUser'
import { useNavigate } from 'react-router'
import { alohaIds } from 'application/domain/useCase/outlets/getOutlets'

import BottomSheet from '../BottomSheet'
import Loader from '../../Loader'

import s from './HelloMW.module.scss'

const HelloMW = () => {
  const navigate = useNavigate()
  const { outlet, tableId } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
    tableId: userReducer.tableId,
  }))
  const [callIsLoading, setCallIsLoading] = useState<boolean>(false)
  const [opened, setOpened] = useState<boolean>(false)

  useEffect(() => {
    const alreadyOpened = sessionStorage.getItem('hello_opened')
    if (!alreadyOpened) {
      setTimeout(() => {
        sessionStorage.setItem('hello_opened', 'true')
        setOpened(true)
      }, 500)
    }
  }, [])

  const closeMW = useCallback(() => setOpened(false), [])

  const showCallPersonal = useMemo(
    () => (tableId && parseInt(tableId, 10) >= 1 ? true : false),
    [tableId],
  )

  const sendTipsHandler = useCallback(() => navigate('/tips'), [navigate])

  const sendCallPersonal = useCallback(() => {
    setCallIsLoading(true)
    callPersonal({
      outlet_id: outlet?.id || 0,
      table_id: parseInt(tableId || '0', 10),
    })
      .then(() => {
        ToastNotify('Официант скоро подойдет')
        setCallIsLoading(false)
        closeMW()
      })
      .catch(() => {
        setCallIsLoading(false)
      })
  }, [outlet, tableId, closeMW])

  return (
    <div>
      <BottomSheet
        opened={opened}
        autoHeight
        onSheetClosed={() => {
          setOpened(false)
        }}
      >
        <div className={s.container}>
          <div className={s.title}>
            Добро пожаловать в сеть{' '}
            {alohaIds.indexOf(outlet?.id || 0) !== -1 ? 'Aloha' : 'кофейн “DonDonuts”'}
          </div>

          {/* <div className={s.barista_block}>
            <div className={s.img}>
              <img src={imagePng} />
            </div>
            <div className={s.barista_info}>
              <div className={s.user_name}>Артем</div>
              <div className={s.user_subtitle}>Ваш бариста</div>
            </div>
          </div> */}
          <div className={s.buttons}>
            {showCallPersonal ? (
              // <div className={s.btn} onClick={sendCallPersonal}>
              //   {callIsLoading ? (
              //     <Loader size={16} color="#000" containerClassName={s.loader} />
              //   ) : (
              //     <div className={s.inner}>
              //       <div className={s.icon}>
              //         <RingSvg />
              //       </div>
              //       <div className={s.btn_title}>
              //         Позвать {outlet?.id === 40 ? 'баристу' : 'официанта'}
              //       </div>
              //     </div>
              //   )}
              // </div>
              <></>
            ) : (
              <div className={s.btn} onClick={sendTipsHandler}>
                <div className={s.inner}>
                  <div className={s.icon}>
                    <CoinsSvg />
                  </div>
                  <div className={s.btn_title}>Оставить чаевые</div>
                </div>
              </div>
            )}

            <div className={`${s.btn} ${s.orange}`} onClick={closeMW}>
              <div className={s.inner}>
                <div className={s.icon}>
                  <BludceSvg />
                </div>
                <div className={s.btn_title}>Посмотреть меню</div>
              </div>
            </div>
          </div>
          {showCallPersonal && (
            <div className={s.horizontal_btn} onClick={sendTipsHandler}>
              <div className={s.icon}>
                <CoinsSvg />
              </div>
              <div className={s.btn_title}>Оставить чаевые</div>
            </div>
          )}
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(HelloMW)
