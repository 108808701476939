const splitByTwo = (data: any) => {
  let arr = []
  if (data && data.length) {
    for (let i = 0; i < data.length; i = i + 2) {
      if (data[i] && data[i + 1]) {
        arr.push({
          0: data[i],
          1: data[i + 1],
        })
      } else if (data[i]) {
        arr.push({ 0: data[i] })
      }
    }
  }
  return arr
}

export default splitByTwo
