import React from 'react'
import ProgressBar from 'react-customizable-progressbar'
import cn from 'classnames'

import s from './CircleProgressBar.module.scss'

type Props = {
  procent: number
  size: 'small'
  color: string
  circleWidth?: number
  backgroundColor?: string
  containerClassName?: string
  circleClassName?: string
  circleContentClassName?: string
  children?: React.ReactNode
}

const CircleProgressBar = ({
  procent,
  size,
  color,
  children,
  containerClassName,
  circleClassName,
  circleContentClassName,
  backgroundColor,
  circleWidth,
}: Props) => {
  return (
    <div className={cn(s.container, s[size], containerClassName || '')}>
      <ProgressBar
        className={cn(s.circle, s[size], circleClassName || '')}
        progress={procent}
        radius={100}
        strokeWidth={circleWidth || 20}
        trackStrokeWidth={circleWidth || 20}
        initialAnimation
        transition="1s ease-in-out"
        trackStrokeColor={backgroundColor}
        strokeColor={color}
      />
      {children ? (
        <div className={cn(s.inner, circleContentClassName || '')}>{children}</div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(CircleProgressBar)
