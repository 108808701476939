import { Order } from 'application/domain/entity/order/Order'
import moment from 'moment'
import React, { useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { ReactComponent as RightTrigger } from 'images/icons/white_right_trigger.svg'

import s from './ActuallyOrder.module.scss'

type Props = {
  openedOrders: Array<Order>
  lastOrder: Order | null
}

const ActuallyOrder = ({ openedOrders, lastOrder }: Props) => {
  const timeout = useRef<any>(null)
  const [seconds, setSeconds] = useState(1)
  const navigate = useNavigate()
  const status = useMemo(() => {
    if (openedOrders.length) {
      if (openedOrders.length >= 2) {
        return `У вас ${openedOrders.length} активных заказа`
      } else {
        if (openedOrders[0] && openedOrders[0].status) {
          switch (openedOrders[0].status) {
            case 'Новый заказ':
              return `Заказ №${openedOrders[0].id} принят`
            case 'Начали готовить':
            case 'Принят':
              const deadline = moment(openedOrders[0].created_at).add(7, 'minutes').valueOf()
              let diff = Math.round((deadline - moment().valueOf()) / 1000)
              diff = diff <= 0 ? 0 : diff
              if (timeout) {
                clearTimeout(timeout.current)
              }

              timeout.current = setTimeout(() => {
                setSeconds(diff)
              }, 1000)
              return `Заказ №${openedOrders[0].id} готовится ${
                diff > 0
                  ? `${Math.floor(diff / 60)}:${diff % 60 < 10 ? `0${diff % 60}` : diff % 60}`
                  : ''
              }`
            case 'Приготовлен':
              return `Заказ №${openedOrders[0].id} готов`
            default:
              return 'Активный заказ'
          }
        }
        return ''
      }
    }
    return ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOrders, seconds])

  const actuallyConfig = useMemo(() => {
    if (openedOrders || lastOrder) {
      if (openedOrders.length) {
        return {
          show: true,
          onClick: () =>
            navigate(
              openedOrders.length > 1
                ? `/orders`
                : `/orders/${openedOrders[0].id}?is_open_order=true`,
            ),
          text: status,
        }
      }
      if (lastOrder) {
        return {
          show: true,
          onClick: () => navigate(`/orders/${lastOrder?.order_id}`),
          text: `Оцените заказ №${lastOrder?.order_id}`,
        }
      }
    }
    return {
      show: false,
      onClick: () => {},
      text: '',
    }
  }, [openedOrders, lastOrder, status, navigate])
  return actuallyConfig.show ? (
    <div className={s.container} onClick={actuallyConfig.onClick}>
      <div className={s.txt}>{actuallyConfig.text}</div>
      <div className={s.icon}>
        <RightTrigger />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(ActuallyOrder)
