import { Button } from 'application/presentation/common/uiComponents'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import BottomSheet from '../BottomSheet'

import s from './ConfirmationMW.module.scss'

type Props = {
  title: string
  onConfirm: Function
  confirmIsLoading?: boolean
}

const ConfirmationMW = ({ title, onConfirm, confirmIsLoading }: Props) => {
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('confirmation') !== -1
  }, [location])

  return (
    <div>
      <BottomSheet
        opened={opened}
        autoHeight
        onSheetClosed={() => {
          if (location.hash.indexOf('confirmation') !== -1) {
            navigation(-1)
          }
        }}
      >
        <div className={s.container}>
          <div className={s.title}>{title}</div>
          <Button
            text="Да"
            color="orange"
            isLoading={confirmIsLoading}
            containerClassName={s.btn_container}
            onClick={() => {
              onConfirm()
              navigation(-1)
            }}
          />
          <Button
            text="Нет"
            onClick={() => navigation(-1)}
            color="white"
            containerClassName={s.btn_container}
          />
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(ConfirmationMW)
