import apiClient from '../apiClient'

export const fetchOrdersHistory = async () => {
  const { data } = await apiClient.get('/api/history')
  return data
}

export const fetchActuallyOrders = async () => {
  const { data } = await apiClient.post('/api/actually')
  return data
}

export const fetchOrder = async (id: number, isOpenOrder: boolean) => {
  const { data } = await apiClient.get(
    `/api/orders?${isOpenOrder ? 'open_order_id' : 'order_id'}=${id}`,
  )
  return data
}
