import { toast } from 'react-toastify'

const ToastNotify = (message: string) => {
  toast(message, {
    position: 'top-center',
    draggable: false,
    autoClose: 2000,
    closeOnClick: false,
    hideProgressBar: true,
    closeButton: false,
  })

  return true
}

export default ToastNotify
