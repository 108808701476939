import { Button } from 'application/presentation/common/uiComponents'
import React, { useEffect, useState } from 'react'
import CoffePng from 'images/coffe_and_ponchik.png'
import { useQuery } from '@tanstack/react-query'
import apiClient from 'application/data/apiClient'
import Loader from 'application/presentation/common/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { setBasket } from 'application/domain/store/basketReducer'
import { RootState } from 'application/domain/store/reduxStore'
import { alohaIds } from 'application/domain/useCase/outlets/getOutlets'

import s from './OrderResultPage.module.scss'

const fetchPaymentStatus = async (id: number) => {
  const { data } = await apiClient.post('/api/payment/check', {
    open_order_id: id,
  })
  return data
}

const OrderResultPage = () => {
  const { outlet } = useSelector(({ userReducer }: RootState) => ({
    outlet: userReducer.outlet,
  }))
  const dispatch = useDispatch()
  const [orderId, setOrderId] = useState<number>(0)
  const [checkPaymentStage, setCheckPaymentStage] = useState<boolean>(true)
  const navigate = useNavigate()

  const { data: paymentStatusData, isLoading: paymentStatusDataIsLoading } = useQuery(
    ['checkpayment', orderId],
    () => fetchPaymentStatus(orderId),
    {
      enabled: checkPaymentStage && orderId && orderId !== 0 ? true : false,
      refetchInterval: 10000,
    },
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('open_order_id')
    if (id) {
      setOrderId(parseInt(id, 10))
    }
  }, [])

  useEffect(() => {
    if (paymentStatusData && paymentStatusData.status === 'Оплата прошла успешно') {
      setCheckPaymentStage(false)
      setBasket([])(dispatch)
    }
  }, [paymentStatusData, dispatch])

  return paymentStatusDataIsLoading || checkPaymentStage ? (
    <div className={`${s.container} ${s.wait_container}`}>
      <Loader containerClassName={s.loader} size={32} />
      <div className={s.subtitle}>Проверяем статус оплаты...</div>
      <div className={s.text}>Можете проверить списание в вашем банковском приложении</div>
      <div className={s.text}>
        Если вы отменили оплату, <br /> просто вернитесь назад
      </div>
      <Button
        text="Назад"
        onClick={() => navigate(-1)}
        color="orange"
        containerClassName={s.btn_container}
      />
    </div>
  ) : (
    <div className={s.container}>
      <div className={s.inner}>
        <div className={s.title}>
          Ваш заказ №{orderId} <br /> оформлен!
        </div>
        <div className={s.subtitle}>
          Скоро ваш заказ будет готов, отслеживайте его статус на электронном табло
        </div>
        <div className={s.subtitle}>Вы можете отследить статус заказа на странице заказа</div>
        <Button
          text="Статус заказа"
          color="orange"
          isLink
          to={`/orders/${orderId}?is_open_order=true`}
          containerClassName={s.back_btn}
        />
        {outlet && alohaIds.indexOf(outlet.id) === -1 ? (
          <div className={s.app_container}>
            <div className={s.app_title}>
              Скачивай наше
              <br />
              мобильное приложение
            </div>
            <div className={s.app_subtitle}>
              И забери свой заказ через
              <br />
              удобное для тебя время!
            </div>
            <div className={s.img_container}>
              <img src={CoffePng} alt="" />
            </div>
            <Button
              text="Скачать"
              onClick={() => {
                window.location.href = 'https://isdon.ru/a'
              }}
              color="orange"
              containerClassName={s.btn_container}
            />
          </div>
        ) : (
          <></>
        )}

        <Button
          text="Вернуться в каталог"
          color="orange"
          isLink
          to="/"
          containerClassName={s.back_btn}
        />
      </div>
    </div>
  )
}

export default React.memo(OrderResultPage)
