/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react'
import logoPng from 'images/coffe_and_ponchik.png'
import { ReactComponent as AlohaIcon } from 'images/icons/aloha_icon.svg'
import { useLocation } from 'react-router'

import s from './Loader.module.scss'

const alohaIds = [40]

const AppLoader = () => {
  const location = useLocation()
  const isAloha = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const localOutlet_id = sessionStorage.getItem('outlet_id')
    const getOutlet_id = params.get('outlet_id')
    const outlet_id = getOutlet_id || localOutlet_id
    return outlet_id && alohaIds.indexOf(parseInt(outlet_id, 10)) !== -1 ? true : false
  }, [location])

  return <div className={s.app_loader}>{isAloha ? <AlohaIcon /> : <img src={logoPng} />}</div>
}

export default React.memo(AppLoader)
