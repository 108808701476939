import { Outlet } from 'application/domain/entity/outlet/Outlet'
import { ReactComponent as CoffeIcon } from 'images/icons/coffe_icon_2.svg'
import cn from 'classnames'
import React, { useCallback } from 'react'

import s from './OutletItem.module.scss'

type Props = {
  data: Outlet
  onClick: Function
}

const OutletItem = ({ data, onClick }: Props) => {
  const clickHandler = useCallback(() => {
    // if (data.is_distance_order) {
    onClick(data)
    // }
  }, [onClick, data])

  return (
    <div className={s.container} onClick={clickHandler}>
      <div
        className={cn({
          [s.left]: true,
          // [s.gray]: !data.is_distance_order
        })}
      >
        <div className={s.title}>{data.name}</div>
        <div className={s.addr}>{data.address}</div>
        <div className={s.time}>{data.time_work}</div>
      </div>
      <div className={s.right}>
        {data.is_distance_order ? (
          <div className={s.ready_container}>
            <CoffeIcon />
            <div className={s.ready_txt}>7 мин.</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default React.memo(OutletItem)
