import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { Order } from 'application/domain/entity/order/Order'
import { useSberPaymentStatus } from 'application/domain/useCase/payments/getPaymentStatus'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import PaymentCheckView from 'application/presentation/common/Payments/PaymentCheckView'

import { useTipsPayment } from './useTipsPayment'
import s from './OrderPage.module.scss'
import OrderContent from './components/OrderContent'
import SuccessOrderRate from './components/SuccessOrderRate'

type Props = {
  data: Order
  isNotActive: boolean
  isCompleted: boolean
}

const OrderPageContent = ({ data, isNotActive, isCompleted }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [paymentCheckStage, setPaymentCheckStage] = useState<boolean>(false)
  const [sberOrderId, setSberOrderId] = useState<string>('')
  const [tipsWithPayment, setTipsWithPayment] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(data.rating || 0)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [rated, setRated] = useState<boolean>(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const orderId = params.get('orderId')
    if (orderId) {
      setSberOrderId(orderId)
      setPaymentCheckStage(true)
    }
  }, [location.search])

  // const isPayResult = useMemo(() => {
  //   if (location.hash && location.hash.indexOf('pay_result') !== -1) {
  //     setIsSuccess(true)
  //     return true
  //   }
  //   setIsSuccess(false)
  //   return false
  // }, [location.search])

  const { onBackPress, onPaySuccess, rateOrder, createTips, tipsSaveIsLoading } = useTipsPayment({
    order: data,
    setPaymentCheckStage,
    setSberOrderId,
    tipsWithPayment,
    setRating,
    setTipsWithPayment,
    setIsSuccess,
    rating,
    setRated,
  })

  const { data: paymentStatusData } = useSberPaymentStatus(sberOrderId)

  const closePage = useCallback(() => {
    if (rating) {
      apiClient
        .post('/api/rating', {
          order_id: data.id ? data.id : data.order_id,
          rating: rating,
        })
        .then(() => {
          queryClient.invalidateQueries(['orders'])
          queryClient.invalidateQueries(['order'])
        })
    }
    setPaymentCheckStage(false)
    navigate(-1)
  }, [data, rating, navigate])

  useEffect(() => {
    if (paymentCheckStage) {
      if (paymentStatusData && paymentStatusData.errorMessage === 'Успешно') {
        onPaySuccess('success')
      }
    }
  }, [paymentStatusData, paymentCheckStage, onPaySuccess])

  useEffect(() => {
    setTipsWithPayment(false)
    if (data && data.rating) {
      setRating(data.rating)
    } else {
      setRating(0)
    }
  }, [data])

  return (
    <div className={s.inner}>
      {isSuccess ? (
        <SuccessOrderRate
          rating={rating}
          tipsWithPayment={sberOrderId ? true : tipsWithPayment}
          baristaName={data && data.barista ? data.barista.name : 'Бариста'}
          closePage={closePage}
        />
      ) : paymentCheckStage ? (
        <PaymentCheckView onBackPress={onBackPress} />
      ) : (
        <OrderContent
          {...{
            data,
            isCompleted,
            isNotActive,
            rated,
            rateOrder,
            rating,
            createTips,
            tipsSaveIsLoading,
            closePage,
          }}
        />
      )}
    </div>
  )
}

export default React.memo(OrderPageContent)
