/* eslint-disable @typescript-eslint/naming-convention */
import { PaymentProps } from 'types/PaymentsType'

import bankPay, { tipsPayProcess } from './bankPay'
import sberPay from './sberPay'

const mainPayProcess = (payData: PaymentProps) => {
  const { selected_pay_method, tips_id } = payData.data
  if (tips_id) {
    return tipsPayProcess(payData)
  }
  switch (selected_pay_method) {
    case 1:
      return sberPay(payData) // card
    case 2:
      return false
    // return applePay(payData) // apple pay
    case 3:
      // return googlePay(payData) // google pay
      return false
    case 4:
      return payData.onSuccess('success') // balance
    case 5:
      return bankPay(payData) // sberpay
    default:
      return bankPay(payData) //card
  }
}

export default mainPayProcess
