import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

import { Outlet } from '../entity/outlet/Outlet'
import { AuthData, AuthUser } from '../entity/user/User'

type UserState = {
  user: AuthUser
  authData: AuthData
  token: string | null
  tableId: string | null
  cookies: string
  outlet: Outlet | null
  orderResultData: any
  app_config: any
}

const initialState: UserState = {
  user: null,
  token: null,
  cookies: '',
  authData: {
    login: '',
    password: '',
  },
  outlet: null,
  tableId: null,
  orderResultData: [],
  app_config: {},
}

// TODO: подправить orderResultAction и setAppConfig

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUserAction: (state, { payload: user }: PayloadAction<AuthUser>) => {
      state.user = user
    },
    setUserTokenAction: (state, { payload: token }: PayloadAction<string | null>) => {
      state.token = token
    },
    setSelectedOutletAction: (state, { payload: outlet }: PayloadAction<Outlet>) => {
      state.outlet = outlet
    },
    setCookiesAction: (state, { payload: cookies }: PayloadAction<string>) => {
      state.cookies = cookies
    },
    setUserAuthDataAction: (state, { payload: authData }: PayloadAction<AuthData>) => {
      state.authData = authData
    },
    setAppConfigAction: (state, { payload: app_config }) => {
      state.app_config = app_config
    },
    setOrderResultData: (state, action) => {
      state.orderResultData = action.payload
    },
    setTableAction: (state, { payload: table }: PayloadAction<string | null>) => {
      state.tableId = table
      if (table) {
        sessionStorage.setItem('table_id', table)
      } else {
        sessionStorage.removeItem('table_id')
      }
    },
  },
})

const {
  setUserAction,
  setUserTokenAction,
  setSelectedOutletAction,
  setOrderResultData,
  setAppConfigAction,
  setTableAction,
} = userReducer.actions

export const setUser = (user: AuthUser) => (dispatch: Dispatch) => dispatch(setUserAction(user))
export const setUserToken = (token: string | null) => (dispatch: Dispatch) =>
  dispatch(setUserTokenAction(token))

export const setTableId = (tableId: string | null) => (dispatch: Dispatch) =>
  dispatch(setTableAction(tableId))

export const setOutlet = (outlet: Outlet) => (dispatch: Dispatch) =>
  dispatch(setSelectedOutletAction(outlet))

export const setOrderResult = (result: any) => (dispatch: Dispatch) =>
  dispatch(setOrderResultData(result))

export const setAppConfig = (app_config: any) => (dispatch: Dispatch) =>
  dispatch(setAppConfigAction(app_config))

export default userReducer.reducer
