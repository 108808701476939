import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://isdon-s2.vsezbs.lol',
  headers: {
    // Authorization: `Bearer ${AsyncStorage.getItem('userToken').then((value: any) => {
    //   return value
    // })}`,
    'Content-Type': 'application/json',
  },
})

export default apiClient
