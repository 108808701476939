import apiClient from 'application/data/apiClient'
import React, { useState, useMemo } from 'react'

import s from './ProductImage.module.scss'

type Props = {
  imageUrl: string
  isDrink?: boolean
  className?: string
  containerClassName?: string
}

const ProductImage = ({ imageUrl, isDrink, className, containerClassName }: Props) => {
  const [isSuccessLoad, setIsSuccessLoaded] = useState<boolean>(true)
  const image = useMemo(() => {
    return isSuccessLoad && imageUrl
      ? {
          cached: true,
          image:
            imageUrl.indexOf('https') !== -1
              ? imageUrl
              : `${apiClient.defaults.baseURL}/${imageUrl}`,
        }
      : {
          cached: false,
          image: isDrink
            ? require('images/default_coffe_new.png')
            : require('images/default_food_new.png'),
        }
  }, [imageUrl, isDrink, isSuccessLoad])

  return (
    <div className={containerClassName || ''}>
      <img
        src={image.image}
        className={`${s.img} ${className || ''}`}
        alt=""
        onError={() => setIsSuccessLoaded(false)}
      />
    </div>
  )
}

export default React.memo(ProductImage)
