import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as SberpaySvg } from 'images/payIcons/sberpay2.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setPayMethod } from 'application/domain/store/payReducer'
import { ReactComponent as MirSvg } from 'images/payIcons/mir.svg'
import { ReactComponent as VisaSvg } from 'images/payIcons/visa.svg'
import { ReactComponent as MasterCardSvg } from 'images/payIcons/mastercard.svg'
import { ReactComponent as CardSvg } from 'images/payIcons/card_icon.svg'
import _ from 'lodash'
import { useUserPaymentCards } from 'application/domain/useCase/payments/userPaymentsCard'
import { RootState } from 'application/domain/store/reduxStore'

import BottomSheet from '../BottomSheet'

import s from './SelectPayMethodMW.module.scss'

const payMethods = [
  {
    id: 1,
    name: 'SberPay',
    icon: <SberpaySvg />,
  },
  { id: 5, name: 'Привязать новую карту', icon: <CardSvg /> },
]

const SelectPayMethodMW = () => {
  const token = useSelector(({ userReducer }: RootState) => userReducer.token)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigation = useNavigate()
  const { data: cardsData } = useUserPaymentCards(token || '')
  const opened = useMemo(() => {
    return location.hash.indexOf('pay_method') !== -1
  }, [location])

  const onItemClick = useCallback(
    (item: { id: number }) => {
      setPayMethod(item.id)(dispatch)
      localStorage.setItem('last_pay_method', item.id.toString())
      navigation(-1)
    },
    [dispatch, navigation],
  )

  const paymentSystems = useMemo(() => {
    if (cardsData) {
      const cards = _.map(cardsData, (item) => {
        let icon = null
        switch (item.payment_system) {
          case 'VISA':
            icon = <VisaSvg />
            break
          case 'MASTERCARD':
            icon = <MasterCardSvg />
            break
          case 'MIR':
            icon = <MirSvg />
            break
          default:
            icon = <CardSvg />
            break
        }
        return {
          ...item,
          name: `${item.payment_system ? item.payment_system : ''} ${`**${
            item.card_number ? item.card_number.replace(/X/g, '') : ''
          }`}`,
          icon: icon,
        }
      })
      return _.concat(cards, payMethods)
    }
    return payMethods
  }, [cardsData])

  return (
    <div>
      <BottomSheet
        opened={opened}
        autoHeight
        onSheetClosed={() => {
          if (location.hash.indexOf('pay_method') !== -1) {
            navigation(-1)
          }
        }}
      >
        <div className={s.container}>
          <div className={s.title}>Способ оплаты</div>
          <div className={s.items}>
            {paymentSystems.map((item) => {
              return (
                <div
                  className={s.item}
                  onClick={() => onItemClick(item)}
                  key={`pay_method_${item.id}`}
                >
                  <div className={s.icon}>{item.icon ? item.icon : ''}</div>
                  <div className={s.name}>{item.name}</div>
                </div>
              )
            })}
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(SelectPayMethodMW)
