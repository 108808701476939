import { Order } from 'application/domain/entity/order/Order'
import { multipleAddToBasket } from 'application/domain/useCase/basket/addToBasket'
import { useOutlets } from 'application/domain/useCase/outlets/getOutlets'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ReactComponent as StarOrangeSvg } from 'images/icons/star_orange.svg'
import { ReactComponent as StarGraySvg } from 'images/icons/star_gray.svg'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button, OutletMiniMap } from 'application/presentation/common/uiComponents'

import { TipsDataType } from '../useTipsPayment'
import s from '../OrderPage.module.scss'

import ActiveItem from './ActiveItem'
import ReviewBlock from './ReviewBlock'

type Props = {
  data: Order
  closePage: Function
  isCompleted?: boolean
  isNotActive?: boolean
  rating: number
  rated: boolean
  rateOrder: Function
  createTips: (data: TipsDataType) => void
  tipsSaveIsLoading: boolean
}

const OrderContent = ({
  data,
  closePage,
  isCompleted,
  isNotActive,
  rating,
  rated,
  rateOrder,
  createTips,
  tipsSaveIsLoading,
}: Props) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const timeout = useRef<any>(null)
  const [seconds, setSeconds] = useState<number>(1)

  const { data: outlets } = useOutlets()

  const selectedBar = useMemo(() => {
    if (data && outlets.length) {
      return _.find(outlets, { id: data.outlet_id })
    }
    return undefined
  }, [outlets, data])

  const repeatOrder = useCallback(() => {
    if (data && data.products) {
      multipleAddToBasket(data.products)(dispatch)
      navigate('/basket')
    }
  }, [data, dispatch, navigate])

  const status = useMemo(() => {
    if (data && data.status) {
      switch (data.status) {
        case 'Новый заказ':
          return 'Заказ принят'
        case 'Начали готовить':
        case 'Принят':
          const deadline = moment(data.created_at).add(7, 'minutes').valueOf()
          let diff = Math.round((deadline - moment().valueOf()) / 1000)
          diff = diff <= 0 ? 0 : diff
          if (timeout) {
            clearTimeout(timeout.current)
          }

          timeout.current = setTimeout(() => {
            setSeconds(diff)
          }, 1000)
          return `Будет готово через ${
            diff > 0
              ? `${Math.floor(diff / 60)}:${diff % 60 < 10 ? `0${diff % 60}` : diff % 60}`
              : '0:00'
          }`
        case 'Приготовлен':
          return 'Готов к получению'
        case 'Завершен':
        default:
          return ''
      }
    }
    return ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, seconds])

  return (
    <div className={s.container_inner}>
      {data ? (
        <>
          <div className={s.title}>
            {isCompleted
              ? `Заказ от\n${moment(data.created_at ? data.created_at : data.create_time).format(
                  'DD.MM.YY HH:mm',
                )}`
              : `№${data ? (data.id ? data.id : data.order_id) : ''}`}
          </div>
          {selectedBar ? (
            <>
              <div className={s.address}>{selectedBar.address}</div>
            </>
          ) : (
            <></>
          )}
          {isNotActive ? (
            <div className={s.work_time}>№ {data ? (data.id ? data.id : data.order_id) : ''}</div>
          ) : (
            <>
              <div className={s.stat_block}>
                <div className={s.stat_txt}>{status}</div>
              </div>
              {selectedBar ? (
                <OutletMiniMap selectedBar={selectedBar} containerClassName={s.map} />
              ) : null}
            </>
          )}

          {isCompleted ? (
            <>
              <div className={s.rating}>
                <div className={s.rating_title}>
                  {rating !== 0 ? 'Спасибо за вашу оценку!' : 'Оцените, пожалуйста'}
                </div>
                {rating === 0 && (
                  <div className={s.rating_subtitle}>
                    насколько Вам понравился кофейный напиток или пончики?
                  </div>
                )}

                <div className={s.stars}>
                  <div className={s.star_container} onClick={() => rateOrder(1)}>
                    {rating >= 1 ? <StarOrangeSvg /> : <StarGraySvg />}
                  </div>
                  <div className={s.star_container} onClick={() => rateOrder(2)}>
                    {rating >= 2 ? <StarOrangeSvg /> : <StarGraySvg />}
                  </div>
                  <div className={s.star_container} onClick={() => rateOrder(3)}>
                    {rating >= 3 ? <StarOrangeSvg /> : <StarGraySvg />}
                  </div>
                  <div className={s.star_container} onClick={() => rateOrder(4)}>
                    {rating >= 4 ? <StarOrangeSvg /> : <StarGraySvg />}
                  </div>
                  <div onClick={() => rateOrder(5)}>
                    {rating === 5 ? <StarOrangeSvg /> : <StarGraySvg />}
                  </div>
                </div>
              </div>
              {rated ? (
                <ReviewBlock
                  rating={rating}
                  //@ts-ignore
                  data={data}
                  createTips={createTips}
                  saveIsLoading={tipsSaveIsLoading}
                />
              ) : null}
            </>
          ) : null}
          {!rated && (
            <div className={`${s.products_container} ${!data?.products.length ? s.empty : ''}`}>
              {data.products
                ? data.products.map((item: any, i: number) => (
                    <ActiveItem data={item} key={`product_${item.name}_${i}`} />
                  ))
                : null}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      {!rated ? (
        !isCompleted ? (
          <Button
            text="Понятно"
            onClick={closePage}
            containerClassName={s.btn_container}
            color="orange"
          />
        ) : (
          <Button
            text="Повторить заказ"
            onClick={repeatOrder}
            containerClassName={s.btn_container}
            color="orange"
          />
        )
      ) : null}
    </div>
  )
}

export default React.memo(OrderContent)
