import React from 'react'
import { ReactComponent as BackSvg } from 'images/icons/go_back_icon.svg'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'

import s from './Header.module.scss'

const ProductHeader = () => {
  const navigation = useNavigate()
  return (
    <div className={s.header}>
      <div className={s.side_item} onClick={() => navigation(-1)}>
        <BackSvg />
      </div>
      <NavLink className={s.side_item} to="#info">
        <InfoSvg />
      </NavLink>
    </div>
  )
}

export default React.memo(ProductHeader)
