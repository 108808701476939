import apiClient from 'application/data/apiClient'

export const fetchProducts = async (outlet_id: number) => {
  const { data } = await apiClient.get(`/api/products?outlet_id=${outlet_id}`)
  return data
}

export const fetchProduct = async (id: number, outlet_id: number) => {
  const { data } = await apiClient.get(`/api/products/${id}?outlet_id=${outlet_id}`)
  return data
}
