import { useQuery } from '@tanstack/react-query'
import { fetchProduct, fetchProducts } from 'application/data/api/products'
import { Product } from 'application/domain/entity/product/Product'

export const useProducts = (outlet_id: number) => {
  const query = useQuery<{ products: Array<Product> }>(
    ['products', outlet_id],
    () => fetchProducts(outlet_id),
    {
      refetchInterval: 20000,
    },
  )

  return { ...query, data: query.data ? query.data.products : [] }
}

export const useProduct = (id: number, outlet_id: number) => {
  const query = useQuery<{ product: Product }>(
    ['product', id, outlet_id],
    () => fetchProduct(id, outlet_id || 1),
    {
      enabled: id !== 0 ? true : false,
    },
  )

  return { ...query, data: query.data ? query.data.product : null }
}
