import { Checkbox } from 'application/presentation/common/uiComponents/InputComponents'
import React from 'react'

import s from './BalanceBlock.module.scss'

type BalanceBlockProps = {
  isBalance: boolean
  setIsBalance: (val: boolean) => void
  balance: number
}

const BalanceBlock = ({ isBalance = false, setIsBalance, balance }: BalanceBlockProps) => {
  return (
    <div className={s.content}>
      <div className={s.title}>
        Баланс <span>{balance} ₽</span>
      </div>
      <Checkbox
        text={'Списать с баланса'}
        value={isBalance}
        onChange={setIsBalance}
        containerClassName={s.checkbox}
        textClassName={s.checkbox_text}
      />
    </div>
  )
}

export default React.memo(BalanceBlock)
