import React from 'react'
import { ReactComponent as BackSvg } from 'images/icons/go_back_icon.svg'
import { useNavigate } from 'react-router'
import cn from 'classnames'

import s from './Header.module.scss'

type Props = {
  title: string
  className?: string
  rightContent?: React.ReactNode
  onBackPress?: Function
}

const Header = ({ title, rightContent, className, onBackPress }: Props) => {
  const navigation = useNavigate()
  return (
    <div className={cn(s.header, className || '')}>
      <div className={s.side_item} onClick={() => (onBackPress ? onBackPress() : navigation(-1))}>
        <BackSvg />
      </div>
      <div className={s.center}>
        <div className={s.title}>{title}</div>
      </div>
      <div className={s.side_item}>{rightContent ? rightContent : <></>}</div>
    </div>
  )
}

export default React.memo(Header)
