import Header from 'application/presentation/common/Header/Header'
import { Button, ErrorMsg } from 'application/presentation/common/uiComponents'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import ToastNotify from 'application/presentation/context/ToastNotify'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import s from './AuthorizationPage.module.scss'
import { useAuthorization } from './useAuthorization'
const AuthorizationPage = () => {
  const navigation = useNavigate()
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const {
    sendCode,
    errMsg,
    regIsLoading,
    phoneIsFull,
    checkIsLoading,
    checkPhone,
    isRegistered,
    btnHidden,
    authorize,
  } = useAuthorization({})

  useEffect(() => {
    checkPhone(phone)
  }, [phone, checkPhone])

  const send = useCallback((passReset?: boolean) => sendCode(phone, passReset), [phone, sendCode])

  const auth = useCallback(
    () =>
      authorize(phone, password, () => {
        navigation(-1)
        ToastNotify('Авторизация прошла успешно')
      }),
    [phone, password, authorize, navigation],
  )
  return (
    <div className={s.container}>
      <Header title={'Авторизация'} />

      <div className={s.content}>
        <div className={s.title}>
          Для оформления мобильного заказа и участия в акции{' '}
          <b>«Каждый шестой стакан - бесплатно»</b> введите Ваш номер телефона
        </div>
        <UniversalInputComponent
          type="phone"
          value={phone}
          onChange={setPhone}
          inputContainerClassName={s.phone_input}
        />
        {phoneIsFull ? (
          isRegistered ? (
            <>
              <UniversalInputComponent
                type="password"
                value={password}
                onChange={setPassword}
                inputContainerClassName={s.phone_input}
                placeholder={'Пароль или пин-код'}
              />
              <div className={s.pass_reset} onClick={() => send(true)}>
                Восстановить доступ
              </div>
            </>
          ) : (
            <Button
              color="orange"
              text="Получить код"
              onClick={send}
              isLoading={checkIsLoading || regIsLoading}
              containerClassName={s.btn}
            />
          )
        ) : (
          <></>
        )}
        {errMsg ? <ErrorMsg text={errMsg} /> : <></>}
      </div>
      {isRegistered && phoneIsFull && !btnHidden && (
        <div className={s.btn_container}>
          <Button
            color="orange"
            text={isRegistered ? 'Войти' : 'Получить код'}
            isLoading={checkIsLoading || regIsLoading}
            onClick={auth}
          />
        </div>
      )}
    </div>
  )
}

export default React.memo(AuthorizationPage)
