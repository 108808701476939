import React, { useMemo, useState } from 'react'
import apiClient from 'application/data/apiClient'

import s from './ImageComponent.module.scss'

type Props = {
  imageUrl: string
  type?: 'barista'
  className?: string
  containerClassName?: string
}

const ImageComponent = ({ imageUrl, type, containerClassName, className }: Props) => {
  const [isSuccessLoad, setIsSuccessLoaded] = useState<boolean>(true)
  const image = useMemo(() => {
    return isSuccessLoad && imageUrl
      ? {
          cached: true,
          image:
            imageUrl.indexOf('https') !== -1
              ? imageUrl
              : `${apiClient.defaults.baseURL}/${imageUrl}`,
        }
      : {
          cached: false,
          image:
            type === 'barista'
              ? require('images/user_default_2.png')
              : require('images/user_default_2.png'),
        }
  }, [imageUrl, type, isSuccessLoad])

  return (
    <div className={containerClassName || ''}>
      <img
        src={image.image}
        className={`${s.img} ${className || ''}`}
        alt=""
        onError={() => setIsSuccessLoaded(false)}
      />
    </div>
  )
}

export default React.memo(ImageComponent)
