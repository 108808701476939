import React from 'react'
import catSvg from 'images/icons/cat.svg'
import catStarSvg from 'images/icons/cat_star.svg'
import catDefaultSvg from 'images/icons/cat_default.svg'
import { Button } from 'application/presentation/common/uiComponents'

import s from '../OrderPage.module.scss'

type Props = {
  rating: number
  tipsWithPayment: boolean
  baristaName: string
  closePage: Function
}

const SuccessOrderRate = ({ rating, tipsWithPayment, baristaName, closePage }: Props) => {
  return (
    <div className={s.success_wrapper}>
      <div className={s.success_container}>
        <div className={s.cat_img}>
          <img src={rating <= 3 ? catDefaultSvg : tipsWithPayment ? catSvg : catStarSvg} />
        </div>
        <div className={s.success_title}>
          {rating <= 3
            ? 'Спасибо, мы станем\nлучше благодаря вам!'
            : tipsWithPayment
              ? 'Спасибо за чаевые!'
              : 'Спасибо за отзыв!'}
        </div>
        {rating > 3 && tipsWithPayment && (
          <div className={s.success_subtitle}>{baristaName} их получил и очень благодарен!</div>
        )}
      </div>
      <Button text="Закрыть" onClick={closePage} color="orange" />
    </div>
  )
}

export default React.memo(SuccessOrderRate)
