import Header from 'application/presentation/common/Header/Header'
import React, { useCallback, useState } from 'react'
import outletPng from 'images/outlet_img.png'
import { Button } from 'application/presentation/common/uiComponents'
import { ReactComponent as CatSvg } from 'images/icons/cat_ok.svg'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/context/ToastNotify'

import s from './FranchisePage.module.scss'

const FranchisePage = () => {
  const [stage, setStage] = useState<number>(1)
  const [sendIsLoading, setSendIsLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [company, setCompany] = useState<string>('')

  const blackBtnClick = useCallback(() => {
    window.location.href = 'https://dondonuts.franch.ru'
  }, [])

  const sendRequest = useCallback(() => {
    setSendIsLoading(true)
    apiClient
      .post('/api/client/sendMailRequest', {
        email,
        phone,
        name,
        company,
        block: 'ЗАЯВКА НА ФРАНШИЗУ',
      })
      .then(() => {
        setSendIsLoading(false)
        setStage(3)
      })
      .catch(() => {
        setSendIsLoading(false)
        ToastNotify('Произошла ошибка')
      })
  }, [email, phone, name, company])

  const openSendRequest = useCallback(() => setStage(2), [])

  return stage === 2 || stage === 3 ? (
    <div className={s.result_container}>
      <Header title={'Франшиза'} />
      {stage === 2 ? (
        <>
          <div className={s.input_content}>
            <UniversalInputComponent
              type="text"
              title="Ваше имя"
              placeholder="Введите имя"
              value={name}
              onChange={setName}
              containerClassName={s.input_container}
            />
            <UniversalInputComponent
              type="text"
              title="Электронная почта"
              placeholder="Введите email"
              value={email}
              onChange={setEmail}
              containerClassName={s.input_container}
            />
            <UniversalInputComponent
              type="phone"
              title="Номер телефона"
              value={phone}
              onChange={setPhone}
              containerClassName={s.input_container}
            />
            <UniversalInputComponent
              type="text"
              title="Название заведения"
              placeholder="Введите название заведения"
              value={company}
              onChange={setCompany}
              containerClassName={s.input_container}
            />
            <Button
              color="orange"
              text="Отправить заявку"
              containerClassName={s.result_btn_container}
              onClick={sendRequest}
              isLoading={sendIsLoading}
            />
          </div>
        </>
      ) : (
        <>
          <div className={s.result_content}>
            <CatSvg />
            <div className={s.result_title}>
              Ваша заявка
              <br />
              отправлена!
            </div>
            <div className={s.result_subtitle}>
              Наши специалисты свяжутся с вами в течении двух рабочих дней
            </div>
          </div>
          <Button
            color="orange"
            text="Хорошо"
            containerClassName={s.result_btn_container}
            isLink
            to="/"
          />
        </>
      )}
    </div>
  ) : (
    <div className={s.container}>
      <Header title={'Франшиза'} className={s.header} />
      <div className={s.img_container}>
        <img src={outletPng} />
        <div className={s.shadow}></div>
        <div className={s.bottom_shadow}></div>
      </div>
      <div className={s.content}>
        <div className={s.title}>
          Хочешь открыть <span>свою</span> кофейню DonDonuts?
        </div>
        <div className={s.subtitle}>
          Или знаешь того, кто хочет инвестировать деньги в кофейный бизнес?
        </div>
        <p>
          С нами это сделать будет гораздо проще и быстрее, чем без нас! Не нужно искать
          поставщиков, думать, какое же оборудование взять, разрабатывать рецепты – все уже готово,
          нужен только твой энтузиазм и вовлеченность.
        </p>
        <p>
          <b>
            У нас есть <span>горячее</span> предложение 🔥
          </b>
          <br />– франшиза DonDonuts на весьма выгодных условиях! Передадим все бизнес-процессы,
          поможем на каждом этапе: от выбора локации до поиска и обучения персонала.
        </p>
        <p>
          Подробности в личку 😉 Оставь контактный телефон и мы с тобой свяжемся в течении двух
          рабочих дней.
        </p>
        <Button
          color="transparent"
          text="Подробнее"
          className={s.black_btn}
          onClick={blackBtnClick}
        />
      </div>
      <Button
        color="orange"
        text="Получить предложение"
        containerClassName={s.btn_container}
        onClick={openSendRequest}
      />
    </div>
  )
}

export default React.memo(FranchisePage)
