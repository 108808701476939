import React, { useMemo } from 'react'
import { ReactComponent as PacketSvg } from 'images/icons/packet.svg'
import tablePng from 'images/table.png'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'

import { SelectIssuingMW } from '../../ModalWindows'

import s from './SelectIssuingBlock.module.scss'

const SelectIssuingBlock = () => {
  const { tableId } = useSelector(({ userReducer }: RootState) => ({
    tableId: userReducer.tableId,
  }))
  const selected = useMemo(() => {
    if (tableId) {
      return tableId === '-2'
        ? {
            img: tablePng,
            isPng: true,
            title: 'В зале',
            subtitle: 'На свободный стол',
          }
        : tableId === '-1'
          ? {
              img: <PacketSvg />,
              isPng: false,
              title: 'На вынос',
              subtitle: '',
            }
          : tableId === '0'
            ? {
                img: <PacketSvg />,
                isPng: false,
                title: 'С собой',
                subtitle: '',
              }
            : {
                img: tablePng,
                isPng: true,
                title: 'В зале',
                subtitle: `Стол №${tableId}`,
              }
    }
    return {
      title: 'Способ выдачи не выбран',
    }
  }, [tableId])
  return (
    <>
      <NavLink className={s.container} to="#select_issuing">
        <div className={s.img}>
          {selected.img ? (
            selected.isPng ? (
              <img
                //@ts-ignore
                src={selected.img}
              />
            ) : (
              selected.img
            )
          ) : (
            <></>
          )}
        </div>
        <div className={s.center}>
          <div className={s.title}>{selected.title}</div>
          {selected.subtitle ? <div className={s.subtitle}>{selected.subtitle}</div> : <></>}
        </div>
      </NavLink>
      <SelectIssuingMW />
    </>
  )
}

export default React.memo(SelectIssuingBlock)
