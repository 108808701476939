import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

type PaymentMethodState = {
  selectedPayMethod: number
  payMethodsMW: {
    opened: boolean
  }
}

const initialState: PaymentMethodState = {
  selectedPayMethod: 1,
  payMethodsMW: {
    opened: false,
  },
}

export const payReducer = createSlice({
  name: 'payReducer',
  initialState,
  reducers: {
    setPaymentMethodAction: (state, { payload: method }: PayloadAction<number>) => {
      state.selectedPayMethod = method
    },
    setPayMwStatusAction: (state, { payload: opened }: PayloadAction<boolean>) => {
      state.payMethodsMW.opened = opened
    },
  },
})

const { setPaymentMethodAction, setPayMwStatusAction } = payReducer.actions

export const setPayMethod = (method: number) => (dispatch: Dispatch) =>
  dispatch(setPaymentMethodAction(method))

export const setPayMwStatus = (opened: boolean) => (dispatch: Dispatch) =>
  dispatch(setPayMwStatusAction(opened))

export default payReducer.reducer
