/* eslint-disable react/require-default-props */
import React from 'react'
import cn from 'classnames'
import ReactTextareaAutosize from 'react-textarea-autosize'
import ReactInputMask from 'react-input-mask'

import ErrorMsg from '../../ErrorMsg'

import s from './UniversalInputComponent.module.scss'

export type UniversalInputComponentType =
  | 'text'
  | 'number'
  | 'password'
  | 'textarea'
  | 'phone'
  | 'datetime'
  | 'time'
  | 'date'

type Props = {
  type: UniversalInputComponentType
  onChange: Function
  value: string | number
  title?: string
  errorMsg?: string
  placeholder?: string
  containerClassName?: string
  inputContainerClassName?: string
  onKeyUp?: Function
  inputRef?: any
  disabled?: boolean
}

const InputComponent = React.memo(
  ({ type, onChange, value, placeholder, onKeyUp, inputRef, disabled }: Props) => {
    switch (type) {
      case 'phone':
        return (
          <ReactInputMask
            mask="+7 (999) 999-99-99"
            maskChar={'_'}
            inputMode="numeric"
            alwaysShowMask
            placeholder={placeholder}
            // inputRef={inputRef}
            // ref={inputRef}
            value={value}
            //   readOnly={readOnly || false}
            onChange={(e: any) => onChange(e.target.value)}
          />
        )
      case 'number':
        return (
          <input
            type="text"
            onChange={(e: any) => onChange(e.target.value.replace(/\D/, ''))}
            value={value}
            readOnly={disabled || false}
            className={s.input}
            ref={inputRef}
            placeholder={placeholder}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
          />
        )
      case 'datetime':
      case 'date':
      case 'time':
        return (
          <input
            type={type === 'datetime' ? 'datetime-local' : type}
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
          />
        )
      case 'textarea':
        return (
          <ReactTextareaAutosize
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
          />
        )
      case 'password':
      case 'text':
        return (
          <input
            type={type}
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
          />
        )
      default:
        return <></>
    }
  },
)

const UniversalInputComponent = ({
  title,
  type,
  onChange,
  value,
  placeholder,
  errorMsg,
  containerClassName,
  inputContainerClassName,
  onKeyUp,
  inputRef,
  disabled,
}: Props) => {
  return (
    <div className={cn(s.container, containerClassName || '')}>
      {title ? <div className={s.title}>{title}</div> : ''}

      <div className={cn(s.input_container, inputContainerClassName || '')}>
        <InputComponent
          type={type || 'text'}
          {...{
            title,
            onChange,
            value,
            placeholder,
            onKeyUp,
            inputRef,
            disabled,
          }}
        />
        {errorMsg ? <ErrorMsg text={errorMsg} /> : <></>}
      </div>
    </div>
  )
}

export default React.memo(UniversalInputComponent)
