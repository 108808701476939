import apiClient from 'application/data/apiClient'
import { CallPersonalRequestData } from 'application/domain/types/RequestTypes'

import { AuthUser } from './../../domain/entity/user/User'

export const fetchUser = async (token: string | null) => {
  if (token) {
    const { data } = await apiClient.get('/api/user', {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
    return data
  }
  return null
}

export const fetchBalance = async (user: AuthUser) => {
  if (user) {
    const { data } = await apiClient.get('/api/user/balance')
    return data
  }
  return null
}

export const callPersonalRequest = async (sendData: CallPersonalRequestData) => {
  const { data } = await apiClient.post('/api/client-request-tables/add', sendData)
  return data
}
