import React from 'react'

import s from './TextInput.module.scss'

type Props = {
  value: string
  onChange: Function
  readOnly?: boolean
  rightTxt?: string
  placeholder?: string
  autoFocus?: boolean
}

const TextInput = ({ value, onChange, readOnly, rightTxt, placeholder, autoFocus }: Props) => {
  return (
    <div className={s.container}>
      <input
        type="text"
        value={value}
        onChange={(e: any) => onChange(e)}
        readOnly={readOnly || false}
        placeholder={placeholder || ''}
        autoFocus={autoFocus ? true : false}
      />
      {rightTxt ? <div className={s.right}>{rightTxt}</div> : <></>}
    </div>
  )
}

export default React.memo(TextInput)
