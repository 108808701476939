import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import { Basket } from '../entity/basket/Basket'
import { Product } from '../entity/product/Product'

type BasketState = {
  basket: Basket
  mergedBasket: Basket
  basketCount: number
  isBalance: boolean
}

const initialState: BasketState = {
  basket: [],
  mergedBasket: [],
  basketCount: 0,
  isBalance: false,
}

export const basketReducer = createSlice({
  name: 'basketReducer',
  initialState,
  reducers: {
    addProductAction: (state, { payload: product }: PayloadAction<Product>) => {
      state.basket.push(product)
      localStorage.setItem('basketData', JSON.stringify(state.basket))
      state.basketCount = state.basket.length
    },
    removeProductAction: (state, { payload: product }: PayloadAction<Product>) => {
      state.basket = _.filter(state.basket, (p) => p.uniq_id !== product.uniq_id)
      state.basketCount = state.basket.length
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    removeProductsAction: (state, { payload: uniq_ids }: PayloadAction<Array<string>>) => {
      state.basket = _.filter(
        state.basket,
        (p: Product) => _.findIndex(uniq_ids, (i: string) => p.uniq_id === i) === -1,
      )
      state.basketCount = state.basket.length
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    editProductAction: (state, { payload: product }: PayloadAction<Product>) => {
      state.basket = _.map(state.basket, (item) => {
        if (product.uniq_ids && product.uniq_ids.length) {
          if (product.uniq_ids.indexOf(item.uniq_id) !== -1) {
            return {
              ...item,
              options: product.options,
              selected_options: product.selected_options,
              temperature: product.temperature,
              varieties: product.varieties,
              variety: product.variety,
            }
          }
        } else {
          if (product.uniq_id === item.uniq_id) {
            return {
              ...item,
              options: product.options,
              selected_options: product.selected_options,
              temperature: product.temperature,
              varieties: product.varieties,
              variety: product.variety,
            }
          }
        }

        return item
      })
      state.basketCount = state.basket.length
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    setBasketDataAction: (state, { payload: basket }: PayloadAction<Basket>) => {
      state.basket = basket
      localStorage.setItem('basketData', JSON.stringify(state.basket))
      state.basketCount = state.basket.length
    },
    setMergedBasketDataAction: (state, { payload: basket }: PayloadAction<Basket>) => {
      state.mergedBasket = basket
    },
    setToggleBalanceAction: (state, { payload: balance }: PayloadAction<boolean>) => {
      state.isBalance = !balance
    },
  },
})

const {
  addProductAction,
  removeProductAction,
  setBasketDataAction,
  removeProductsAction,
  editProductAction,
  setMergedBasketDataAction,
  setToggleBalanceAction,
} = basketReducer.actions

export const basketIsNotEmpty = (basket: Basket) => (basket.length ? true : false)

export const addProduct = (product: Product) => (dispatch: Dispatch) =>
  dispatch(addProductAction(product))

export const editProduct = (product: Product) => (dispatch: Dispatch) =>
  dispatch(editProductAction(product))

export const removeProduct = (product: Product) => (dispatch: Dispatch) =>
  dispatch(removeProductAction(product))

export const removeProducts = (uniq_ids: Array<string>) => (dispatch: Dispatch) =>
  dispatch(removeProductsAction(uniq_ids))

export const setBasket = (basket: Basket) => (dispatch: Dispatch) =>
  dispatch(setBasketDataAction(basket))

export const setMergedBasket = (basket: Basket) => (dispatch: Dispatch) =>
  dispatch(setMergedBasketDataAction(basket))

export const toggleBalance = (balance: boolean) => (dispatch: Dispatch) =>
  dispatch(setToggleBalanceAction(balance))

export default basketReducer.reducer
