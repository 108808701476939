import apiClient from '../apiClient'

export const fetchUserPaymentCards = async () => {
  const { data } = await apiClient.get('/api/payment/cards')
  return data
}

export const deleteUserPaymentCardsRequest = async (id: number) => {
  const { data } = await apiClient.delete(`/api/payment/cards/${id}`)
  return data
}
